export const validateField = (currentField, state) => {
  const { value, defaultErrors = {} } = state,
    required = currentField?.model?.required,
    name = currentField?.model?.name,
    title = currentField?.model?.title,
    validationDataModels = currentField?.model?.validationDataModels;
  let validationResult = {
    isValid: true,
    error: Array(),
  };

  if (!value && required === true) {
    validationResult.isValid = false;
    let requiredMessage = 'This field is required.';
    validationResult.error.push(requiredMessage);
  } else if (
    currentField.model.templateId === '{FEE9D736-5EC8-4CF0-AA34-0BBCE76C5342}' &&
    !(typeof value === 'string')
  ) {
    validationResult.isValid = false;
    let requiredMessage = 'This field is required.';
    validationResult.error.push(requiredMessage);
  } else if (
    (!value || value.length === 0) &&
    required === true &&
    currentField?.model?.items?.length
  ) {
    validationResult.isValid = false;
    let requiredMessage = 'This field is required.';
    validationResult.error.push(requiredMessage);
  } else if ((!value || value.length === 0) && defaultErrors.required) {
    validationResult.isValid = false;
    let requiredMessage = defaultErrors.required;
    validationResult.error.push(requiredMessage);
  } else if (validationDataModels?.length) {
    validationDataModels.forEach((validator) => {
      let paramtersRegex = validator.parameters;
      if (typeof paramtersRegex === 'string' && paramtersRegex.length > 0) {
        let regularData = JSON.parse(paramtersRegex);
        paramtersRegex = regularData.regularExpression;
      }
      if (paramtersRegex && paramtersRegex !== '^$') {
        let regex = new RegExp(paramtersRegex);
        let match = value.match(regex);

        if ((!required && value.length > 0) || required) {
          validationResult.isValid = match === null ? false : true;
          if (!validationResult.isValid) {
            validationResult.error.push(validator.message.replace('{0}', title));
          }
        }
      }
    });
  }
  return validationResult;
};
