import $ from 'jquery';
export default function navJquery() {
  $(function ($) {
    (function ($) {
      'use strict';
      var lastScrollTop = $(window).scrollTop(),
        delta = 5;
      $(window).on('scroll', function () {
        var $el = $('body');
        var offsetTop = $(window).scrollTop();
        var cssClass = 'sticky';

        if ($('nav.residence-nav').length) {
          // residence behaviour
          var st = $(this).scrollTop();

          if (Math.abs(lastScrollTop - st) <= delta) {
            return;
          }

          if (st > 15 && st > lastScrollTop) {
            // downscroll code
            $el.addClass(cssClass);
          } else {
            // upscroll code
            $el.removeClass(cssClass);
          }
          lastScrollTop = st;
        } else {
          // corporate behaviour
          if (offsetTop > 10) {
            // scroll code + offset
            $el.addClass(cssClass);
          } else {
            // zero point
            $el.removeClass(cssClass);
          }
        }
      });

      var scrollPos;
      $('.menu-toggler').on('click', function () {
        var isMenuOpen = $('.menu-toggler').hasClass('collapsed');
        if (isMenuOpen) {
          scrollPos = $(window).scrollTop();
          $(window).scrollTop(0);
          $('.main-nav').addClass('menuOpen');
        } else {
          $('.main-nav').removeClass('menuOpen');
          $(window).scrollTop(scrollPos);
        }
      });
    })($);
  });
}
