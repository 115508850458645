import React from 'react';
import clsx from 'clsx';
import { Navbar as BootstapNavbar } from 'react-bootstrap';

const ariaId = 'mainMenu';
export const NavBar = ({ makeNonSticky, children }) => {
  return (
    <BootstapNavbar
      expand="lg"
      bsPrefix={clsx(
        'residence-nav',
        makeNonSticky ? 'thank-you-nav-static' : 'thank-you-nav',
        'main-nav'
      )}
      aria-label="Main Navigation"
    >
      {children}
    </BootstapNavbar>
  );
};

export const Collapse = ({ children }) => (
  <BootstapNavbar.Collapse id={ariaId}>
    <ul className="menu-list" role="menu">
      {children}
    </ul>
  </BootstapNavbar.Collapse>
);

export const Toggle = () => (
  <BootstapNavbar.Toggle className="menu-toggler hamburger" aria-controls={ariaId}>
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </BootstapNavbar.Toggle>
);

export default NavBar;
