import React, { useEffect } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

function reportWhitePaperWindowSize() {
  var whitePaperContainers = document.getElementsByClassName('white-paper-container');

  if (whitePaperContainers !== null && whitePaperContainers.length > 0) {
    for (var i = 0; i < whitePaperContainers.length; i++) {
      var whitePaperContainer = whitePaperContainers[i];
      if (whitePaperContainer !== null) {
        var leftColumn = whitePaperContainer.querySelectorAll('.white-paper-left-column')[0];
        var rightColumn = whitePaperContainer.querySelectorAll('.white-paper-right-column')[0];

        if (leftColumn !== null && rightColumn !== null) {
          if (window.innerWidth >= 992) {
            whitePaperContainer.insertBefore(leftColumn, rightColumn);
          } else {
            whitePaperContainer.insertBefore(rightColumn, leftColumn);
          }
        }
      }
    }
  }
}

const TwoColumnSublayout = ({ rendering }) => {
  useEffect(() => {
    window.addEventListener('resize', reportWhitePaperWindowSize);
    window.addEventListener('load', reportWhitePaperWindowSize);
    reportWhitePaperWindowSize();
  }, []);
  return (
    <div className="intro">
      <div className="container">
        <div className="row white-paper-container">
          <div className="col-md-6 white-paper-left-column">
            <Placeholder rendering={rendering} name="tc-left-column" />
          </div>
          <div className="col-md-6 white-paper-right-column">
            <Placeholder rendering={rendering} name="tc-right-column" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoColumnSublayout;
