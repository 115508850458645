import React, { useEffect } from 'react';
import { Style } from '../../../../../Foundation/Utils/stringUtils';

const CheckboxTemplate = ({
  selected,
  residence,
  handler,
  markers,
  markerIcon,
  highligtedMarkerIcon,
  maxSelectedItem,
  selectedItemList,
  setSelectedItemList,
  showLabel,
}) => {
  const [value, setValue] = React.useState(residence.isHover || residence.checked || false);
  const inputRef = React.createRef(null);

  useEffect(() => {
    if (residence.isHover || residence.checked) {
      setValue(true);
    } else {
      setValue(false);
    }
  }, [residence.checked, residence.isHover]);
  useEffect(() => {
    if (maxSelectedItem !== 0) {
      if (residence.checked) {
        if (selectedItemList.length + 1 > maxSelectedItem) {
          selectedItemList[0] && selectedItemList[0].click();
          setSelectedItemList((List) => {
            let newList = List;
            newList.shift();

            return [...newList, inputRef.current];
          });
        } else {
          setSelectedItemList((List) => [...List, inputRef.current]);
        }
      } else {
        setSelectedItemList((List) => {
          let newList = List;
          const removeIndex = newList.map((item) => item.id).indexOf(inputRef.current.id);
          removeIndex !== -1 && newList.splice(removeIndex, 1);
          return [...newList];
        });
      }
    }
  }, [residence.checked]);
  const handleMouseEnter = () => {
    var relMarker = markers.filter((x) => x.id === residence.Id)[0];

    if (relMarker) {
      relMarker.setIcon(highligtedMarkerIcon);
    }
  };

  const handleMouseLeave = () => {
    var relMarker = markers.filter((x) => x.id === residence.Id)[0];
    if (relMarker && !value) {
      relMarker.setIcon(markerIcon);
    }
  };

  const handleInputChange = (e) => {
    var marker = markers.filter((x) => x.id === e.target.value)[0];
    var currentValue = selected;
    setValue(!value);
    if (e.target.checked === true) {
      residence.checked = true;

      if (highligtedMarkerIcon && Object.keys(highligtedMarkerIcon).length !== 0) {
        if (marker) {
          marker.setIcon(highligtedMarkerIcon);
          marker.isActive = true;
        }
      }

      if (
        currentValue.ParentId ||
        (typeof currentValue === 'string' && !currentValue.includes(e.target.value))
      ) {
        currentValue =
          currentValue.length > 0
            ? currentValue + '|{' + e.target.value + '}'
            : '{' + e.target.value + '}';
      }
    } else {
      residence.checked = false;

      if (marker && Object.keys(marker).length !== 0) {
        marker.setIcon(markerIcon);
        marker.isActive = false;
      }

      if (typeof currentValue === 'string' && currentValue.includes(e.target.value)) {
        currentValue = currentValue.replace('|{' + e.target.value + '}', '');
        currentValue = currentValue.replace('{' + e.target.value + '}', '');
      }
    }
    if (currentValue.length > 0 && currentValue[0] === '|') {
      currentValue = currentValue.substring(1, currentValue.length);
    }
    handler(currentValue);
  };

  const handleClick = () => {
    handleInputChange({ target: { value: residence.Id, checked: !value } });
  };

  return (
    <div className={`${residence.colClass} res-card-column`}>
      <div
        style={Style(
          residence.isHover || residence.checked ? 'border: solid 2px' : 'border: none;'
        )}
        id={`res-card-${residence.Id}`}
        className={`${residence.cardClass} res-selection-card`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <div className={'card-body'}>
          <div className={'row'}>
            <div className={'col-2 text-center align-self-center'}>
              <label htmlFor={residence.Id} className="hidden">
                {residence.Name}
              </label>
              <input
                ref={inputRef}
                id={residence.Id}
                name="chkResidence"
                type="checkbox"
                value={residence.Id}
                checked={value}
                className={'form-control-lg'}
                onChange={handleClick}
              />
            </div>
            <div className={'col-10'}>
              <h2 className={'m-1 res-card-title card-title'}>{residence.Name}</h2>
              {showLabel ? (
                <>
                  <p name="residence-address" className="healthcare-professionals card-text">
                    {residence.Address1}
                  </p>
                  {residence.HealthcareProfessionalsLabel ? (
                    <span
                      name="healthcare-professionals-labels"
                      className="healthcare-professionals d-none d-sm-inline"
                    >
                      {residence.HealthcareProfessionalsLabel}
                    </span>
                  ) : null}
                  {residence.HealthcareProfessionalsMobileLabel ? (
                    <span
                      name="healthcare-professionals-labels-mobile"
                      className="healthcare-professionals d-inline d-sm-none"
                    >
                      {residence.HealthcareProfessionalsMobileLabel}
                    </span>
                  ) : null}
                </>
              ) : (
                <p name="residence-address" className="card-text">
                  {residence.Address1}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const checkbox = ({
  selected,
  residences,
  showMap,
  handler,
  markers,
  highligtedMarkerIcon,
  markerIcon,
  maxSelectedItem,
  selectedItemList,
  setSelectedItemList,
  showLabel,
  legendText,
}) => {
  if (!residences?.length) {
    return null;
  }

  return (
    <fieldset className="no-space">
      <legend className="hidden">{legendText}</legend>
      <div className="row">
        {residences.map((item, i) => {
          const marker = markers.find((m) => m.id === item.Id);
          if (marker) {
            item.checked = !item.checked && marker.isActive ? true : marker.isActive;
            item.isHover = marker.isHover;
          }
          if (showMap === true || residences.length === 1 || (i + 1) % 2 !== 0) {
            if (showMap === true || i === residences.length - 1) {
              item.colClass = 'col-sm-12';

              if (showMap === false) item.cardClass = 'single-card';
            } else {
              item.colClass = 'col-sm-6';
              item.cardClass = '';
            }
          } else {
            item.colClass = 'col-sm-6';
          }

          return (
            <CheckboxTemplate
              residence={item}
              key={i}
              handler={handler}
              markers={markers}
              markerIcon={markerIcon}
              highligtedMarkerIcon={highligtedMarkerIcon}
              selected={selected}
              maxSelectedItem={maxSelectedItem}
              selectedItemList={selectedItemList}
              setSelectedItemList={setSelectedItemList}
              showLabel={showLabel}
            />
          );
        })}
      </div>
    </fieldset>
  );
};
export default checkbox;
