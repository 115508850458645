import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { ReactComponent as PhoneIcon } from '../../../../assets/icons/phone.svg';

const ResidencePhoneCTA = ({ fields }) => {
  const text = fields?.text;
  const phone = 'tel:' + fields?.phone;

  if (!text) {
    if (isExperienceEditorActive()) {
      return <p>Error displaying ResidencePhoneCTA</p>;
    }
    return null;
  }

  return (
    <a className="call2action btn residencecta contact" href={phone ? phone : '#'}>
      <PhoneIcon />
      {' ' + text}
    </a>
  );
};

export default ResidencePhoneCTA;
